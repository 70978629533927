import React, { useEffect } from "react";
import mainFoodImage from "../assets/Food Images/Tlayuda.jpg"

function Catering() {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'instant' });
    }, [])

    return(
        <div className="flex justify-center align-middle">
            <div className="lg:px-72 flex justify-between items-center my-[2.5rem] sm:my-[12.5rem] lg:my-[7.5rem] mx-6 rounded-xl gap-12 lg:gap-20 flex-col md:flex-row">
                <img loading="lazy" className="sm:max-w-[30rem] md:max-w-[18rem] lg:max-w-[30rem] rounded-3xl aspect-square inset-0" src={mainFoodImage} alt="A freshly cooked tlayuda topped with baked beans, fresh queso, and carne enchilada" />
                <div className="flex flex-col justify-center">
                    <h1 className="text-4xl lg:text-6xl font-semibold">Catering & Events</h1>
                    <p className="text-lg lg:text-xl my-6">
                        Whether you're planning a corporate luncheon, a milestone celebration, 
                        or an intimate gathering with friends, Sabor Mixteco is dedicated to delivering 
                        exceptional service and exquisite cuisine that will elevate your event to new 
                        heights.
                    </p>
                    <button className="w-48 text-xl text-white bg-orange-500 p-2 rounded-full ">
                        <a alt="Go to Plan Catering Form" href="https://forms.gle/oDLiwHw9WgnbtwS4A" target="_blank" rel="noreferrer">
                        Plan Event
                        </a>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Catering