import React, { useEffect, useState } from "react"
import NavBar from "./components/NavBar"
import { BrowserRouter, Routes, Route } from "react-router-dom"

import Home from "./components/Home"
import ErrorPage from "./ErrorPage"
import Menu from "./components/Menu"
import About from "./components/About"
import Catering from "./components/Catering"
import Footer from "./components/Footer"
import './styles.css'; 
import Discount from "./components/Discount"
import DiscountSteps from "./components/DiscountSteps"
import TacoTuesday from "./components/TacoTuesday"

function App() {
    
    const [isSticky, setIsSticky] = useState(false)

    useEffect(() => {
        const handleScroll = () => {
            if(window.scrollY > 0) {
                setIsSticky(true)
            } else {
                setIsSticky(false)
            }
        }
        window.addEventListener('scroll',handleScroll)

        return () => {
            window.removeEventListener('scroll',handleScroll)
        }
    }, [])
    
    const navClasses = () => {
        const display = isSticky ? 'sticky top-[8rem] z-10' : '';
        return `p-3 lg:px-36 xl:px-72 flex justify-between items-center bg-white bg-opacity-90 ${display}`
    }

    const tacoTuesdayClasses = () => {
        const display = isSticky ? 'sticky top-16 z-10' : '';
        return `border-t-[1px] border-t-white h-104sm:text-sm md:text-lg w-full z-20 flex max-h-[4rem] bg-orange-500 py-2 px-8 justify-between items-center text-white font-bold lg:px-32 xl:px-80 ${display}` 
    }

    const discountClasses = () => {
        const display = isSticky ? 'sticky top-0 z-10' : '';
        return `h-20 sm:text-sm md:text-lg w-full z-20 flex max-h-[4rem] bg-orange-500 py-2 px-8 justify-between items-center text-white font-bold lg:px-32 xl:px-80 ${display}` 
    }

    const [showDiscountSteps, setShowDiscountSteps] = useState(false)
    const showSteps = () => setShowDiscountSteps(!showDiscountSteps) 

    return (
        <BrowserRouter>
            {showDiscountSteps ? <DiscountSteps hide={showSteps} /> : ""}
            <Discount css={discountClasses()} hide={showSteps} />
            <TacoTuesday css={tacoTuesdayClasses()} />
            <NavBar css={navClasses()}/>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/menu" element={<Menu />} />
                <Route path="/about" element={<About />} />
                <Route path="/catering" element={<Catering />} />
                <Route path="*" element={<ErrorPage/>} />
            </Routes>
            <Footer />
        </BrowserRouter>
    )
}

export default App