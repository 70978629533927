import React from "react";
import facebookLogo from "../assets/Facebook.svg"
import instagramLogo from "../assets/Instagram.svg"

function Footer () {
    return(
        <footer className="flex justify-between px-2 lg:px-32 xl:px-64">
            <div className="flex">
                <a href="https://www.facebook.com/profile.php?id=61557240952529"><img  alt="Facebook logo" width="30" src={facebookLogo} /></a>
                <a href="https://www.instagram.com/taqueriasabormixteco/"><img alt="Instagram logo" width="30" src={instagramLogo} /></a>
            </div>
            <p className="text-xs sm:text-sm md:text-md">&copy; 2024 Taqueria Sabor Mixteco. All Rights Reserved.</p>
        </footer>
    )
}

export default Footer