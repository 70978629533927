import React from "react";

function TacoTuesday(props) {

    const {css} = props



    return (
        <div id="TuesdayBar" className={css}>
            <section className="h-[4rem] overflow-hidden whitespace-nowrap flex gap-4 items-center">
                <div className="loop-text flex gap-4">
                    <p className="inline-block">Join us on Taco Tuesday to get more tacos for you buck! </p>
                    <p className="inline-block">Come in on Tlayuda Monday for the tlayuda special!</p>
                </div>
                <div className="loop-text flex gap-4">
                    <p className="inline-block">Join us on Taco Tuesday to get more tacos for you buck!</p>
                    <p className="inline-block">Come in on Tlayuda Monday for the tlayuda special!</p>
                </div>
            </section>
        </div>
    )
}

export default TacoTuesday