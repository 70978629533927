import MenuItem from "./MenuItem";

// let cincoDeMayo = [
//     MenuItem("Tlayuda With Chorizo","19.00",""),
//     MenuItem("Suadero Tacos","11.85",""),
//     MenuItem("Pastor Tacos","11.85",""),
//     MenuItem("Chicken Tacos","11.85",""),
//     MenuItem("Chorizo Tacos","11.85",""),
//     MenuItem("Carbon Tacos","11.85",""),
//     MenuItem("Tinga Tostadas","7.90","Two crispy tostadads topped with beans, quesos, lettuce, sosur cream and tinga chicken covered with chipotle sauce"),
//     MenuItem("Mangonada","7.85",""),
// ]
let tlayudaMonday = [
    MenuItem("Chorizo Tlayuda Special","19.00","A toast coverede with chorizo, pork lard, refried beans, cabbage, lettuce, tomato, avocado and cheese."),
]

let tacoTuesday = [
    MenuItem("Suadero Tacos","11.85","Four tacos filled with braised beef"),
    MenuItem("Pastor Tacos","11.85","Four tacos filled with marinated pork in adobo"),
    MenuItem("Chicken Tacos","11.85","Four tacos filled with chicken"),
    MenuItem("Chorizo Tacos","11.85","Four tacos filled with pork mexican sausage"),
    MenuItem("Carbon Tacos","11.85","Four tacos filled with carne asada"),
]

let appetizers = [
    MenuItem("Guacamole", "9.95", "Avocado, onion, jalapeño, tomato, cilantro served with chips"),
    MenuItem("Quesadillas", "12.00", "A øour tortilla filled with Monterrey Jack, queso Oaxaca, guacamaole, cream, and salsa. Add chicken for 4.00"),
    MenuItem("Queso Fundido", "8.95", "Melted Jack cheese, Queso Oaxaca, jalapeño peppers, cilantro and served with corn tortillas. Add chorizo for 1.50"),
    MenuItem("Sopes", "6.50", "Two sopes topped with pork lard, beans, cheese, lettuce, and onion. Add chorizo for 2.00"),
    MenuItem("Tlayudas", "14.95", "Pork lard, refried beans, cabbage, lettuce, tomato, avocado and cheese. Add grilled beef, chorizo or carne enchilada(pork) for 6.00"),
    MenuItem("Chips y Salsa", "3.00", ""),
]

let tacos = [
    MenuItem("Al Pastor", "3.75", "Taco filled with marinated pork in adobo and pineapple"),
    MenuItem("Suadero", "3.95", "Taco filled with braised beef"),
    MenuItem("Chorizo", "3.75", "Taco filled with pork mexican sausage"),
    MenuItem("Tacos Al Carbon", "3.95", "Taco filled with carne asada"),
    MenuItem("Tacos De Lengua", "4.95", "Taco filled with cow tongue"),
    MenuItem("Tacos De Pollo", "3.75", "Taco filled with Chicken"),
]

let burritos = [
    MenuItem("Burrito Al Pastor", "10.95", "Marinated pork"),
    MenuItem("Burrito De Chorizo", "10.95", "Mexican pork sausage"),
    MenuItem("Burrito De Suadero", "10.95", "Braised beef"),
    MenuItem("Burrito De Pollo", "10.95", "Chicken"),
]

let alambres = [
    MenuItem("Al Pastor", "18.95", "Marinated pork"),
    MenuItem("Suadero", "18.95", "Braised beef"),
    MenuItem("Bistec", "18.95", "Beef"),
    MenuItem("Chorizo", "18.95", "Mexican pork sausage"),
    MenuItem("Campechanos", "20.95", "Suadero and chorizo"),
    MenuItem("Chicken", "17.95", "Chicken"),
]

let tortas = [
    MenuItem("Al Pastor", "13.50", "Marinated pork in adobo"),
    MenuItem("Chorizo", "13.50", "Pork Mexican Chorizo"),
    MenuItem("Carne Asada", "14.50", "Grilled beef"),
    MenuItem("Jamon", "10.00", "Ham"),
]

let signatures = [
    MenuItem("Enchiladas Verdes", "16.95", "Three chicken enchiladas served with green salsa, sour cream, onion, queso fresco and a side of rice and beans"),
    MenuItem("Enchiladas De Mole", "17.95", "Three traditional mole enchiladas stuffed with chicked served with rice, beans, queso fresco, and radishes"),
    MenuItem("Carne Asada", "29.75", "Grilled marinated New York strip. Served with rice, beans, grilled onions and tomatoes."),
    MenuItem("Bistec Con Salsa Pasilla", "17.95", "Beef cooked in salsa pasilla(Tomato, chile pasilla, garlic) served with tortillas, rice and beans"),
    MenuItem("Bistec A La Mexicana", "17.95", "Beef cooked in tomato, onion, cilantro, jalapeno and served with tortillas, rice, and beans"),
    MenuItem("Chileajo", "17.95", "Traditional Oaxacan pork stew made with potatoes, dried peppers, garlic, herbs and pickled onions"),
    MenuItem("Camarones En Salsa Pasilla", "22.95", "Shrimps cooked in salsa pasilla(Tomato, chile pasilla, garlic, onion)"),
    MenuItem("Camarones Al Mojo De Ajo", "22.95", "Shrimps cooked with garlic, onions, guajillo and white wine"),
    MenuItem("Pancita", "12.00", "Traditional mexican cow tripe and feet soup with guajillo peppers"),
]

let sides = [
    MenuItem("Mexican Rice", "3.50", ""),
    MenuItem("Beans", "3.50", ""),
    MenuItem("Nopales", "3.50", ""),
    MenuItem("Tortillas", "1.50", ""),
    MenuItem("Salad", "7.00", "Romaine lettuce, tomatoes, onions, avocado and caesar dressing. Add grilled chicken for 4.00, or grilled shrimps for 6.00"),
]

let drinks = [
    MenuItem("Soft Drinks", "1.95", ""),
    MenuItem("Bottled Water", "1.95", ""),
    MenuItem("Mexican Sodas", "3.95", ""),
    MenuItem("Aguas Frescas", "3.00", ""),
    MenuItem("Frozen Drink", "7.00", "Choice of piña colada, or mango daiquiri"),
    MenuItem("Cafe De Olla(Coffee)", "3.00", ""),
]

var menu = {
    "Appetizers" : ["", appetizers],
    "Taco Tuesday" : ["Only available on Tuesdays", tacoTuesday],
    "Tlayuda Monday" : ["Only available on Monday", tlayudaMonday],
    "Tacos" : ["Served with cilantro and onions. Add rice and beans for 3.50", tacos],
    "Alambres" : ["Poblano peppers, onions, cilantro, and melted cheese served with six tortillas. Add rice and beans for 3.50", alambres],
    "Burrito" : ["Served with rice, beans, lettuce, salsa, cheese and sour cream and served with your choice of filling", burritos],
    "Tortas" : ["Mexican sandwich filled with refried beans, onion, tomato, mayo, jalapeño, and avocado", tortas],
    "Signature Dishes" : ["", signatures],
    "Sides" : ["", sides],
    "Drinks" : ["", drinks],
}

// This code can allow you to only show certaiin menu categories based on the weekday
// let date = new Date()

// let daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
// let currWeekDay = daysOfWeek[date.getDay()]

// menu = Object.entries(menu).reduce(((acc, [key, value]) => {
//     if(value !== null) {
//         acc[key] = value
//     }
//     return acc
// }, {}))

// Lunch Menu
let huevos = [
    MenuItem("Huevos A La Mexicana", "9.95", "Scrambled eggs with tomato"),
    MenuItem("Huevos Con Chorizo", "10.95", "Scrambled eggs with mexican pork sausage"),
    MenuItem("Huevos En Salsa Verde", "9.95", "Scrambled or fried eggs with green salsa"),
    MenuItem("Huevos Divorciados", "9.95", "Fried eggs with green and red salsa"),
    MenuItem("Enfrijoladas", "8.95", "Black beans sauce, cheese, sourcream, onions, cilantro and avocado. Add eggs for 2.00, chorizo or carne enchilada for 4.00"),
    MenuItem("Chilaquiles Verdes", "8.95", "Tortilla chips covered with green tomatillo salsa, onions, cream, rice and beans. Add eggs for 2.00, chorizo or carne enchilada for 4.00"),
]

let combos = [
    MenuItem("Tacos Al Pastor", "9.95", "Three tacos al pastor with pineapple"),
    MenuItem("Tacos De Chorizo", "9.95", "Threee tacos with mexican sausage"),
    MenuItem("Beef Alambre Tacos", "11.50", "Three tacos filled with carne asada, poblano pepper, onions, and cheese"),
    MenuItem("Pastor Alambre Tacos", "10.95", "Three tacos al pastor with pineapple and filled with poblano pepper, onions, and cheese"),
    MenuItem("Chorizo Alambre Tacos", "10.95", "Three tacos filled with mexican pork sausage, poblano pepper, onions and cheese"),
    MenuItem("Chicken Alambre Tacos", "10.95", "Three tacos filled with chicken, poblano pepper, onions and cheese"),
]

let lunchBurritos = [
    ...burritos, 
    MenuItem("Egg Burrito", "9.95", "Scrambled eggs")
]

let lunchMenu = {
    "Huevos(Eggs)" : ["Served with rice, beans and corn tortillas", huevos],
    "Combos" : ["", combos],
    "Burritos" : ["Served with rice, beans, lettuce, salsa of your choice, cheese, sour cream and your choice of filling", lunchBurritos],
    "Drinks" : ["", drinks],
}

let menus = {menu, lunchMenu}

export default menus
