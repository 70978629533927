import React from "react";

function Discount(props) {

    const {hide, css} = props

    return (
        <div id="DiscountBar" className={css}>
            <p className="font-thin">Get 10% off your next order. Limited time offer!!!</p>
            <button onClick={hide} className="sm:p-0 md:p-2 border border-white">Get Discount</button>
        </div>
    )
}

export default Discount