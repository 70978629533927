import React from "react";

function MenuItemCard(props) {
    let { item } = props
    let name = item.getName()
    let cost = `$${item.getCost()}`
    let desc = item.getDesc()
    
    return (
        <div className="py-3 w-full border-b-2 border-black md:border md:p-6 ">
            <h4 className="text-lg sm:text-xl">{name} {cost === "$0" ? "" : cost}</h4>
            {desc === "" ? "" : <p className="text-sm sm:text-md">{desc}</p>}
        </div>
    )
} 

export default MenuItemCard