import React from "react";

function DiscountSteps(props) {

    const {hide} = props

    return(
        <div className="w-full h-full fixed z-30 flex justify-center items-center">
            <div onClick={hide} className="bg-black bg-opacity-60 w-full h-full "></div>
            <div className="bg-white absolute flex flex-col p-8 gap-4">
                <div className="flex justify-between">
                    <h1 className="text-3xl font-bold">Get Discount</h1>
                    <button onClick={hide} className=" hover:bg-white hover:text-red-500">X</button>
                </div>
                <div className="flex flex-col gap-6">
                    <div>
                        <h2 className="font-bold text-2xl">Step 1</h2>
                        <p>Follow us on <a target="_blank" rel="noreferrer" className="link" href="https://www.instagram.com/sabormixtecotaqueria/" alt="Link to Taqueria Sabor Mixteco instagram">instagram</a> or <a target="_blank" rel="noreferrer" className="link" href="https://www.facebook.com/SaborMixtecoTaqueria" alt="Link to Taqueria Sabor Mixteco facebook">facebook</a></p>
                    </div>
                    <div>
                        <h2 className="font-bold text-2xl">Step 2</h2>
                        <p>Show your waiter that you follow us</p>
                    </div>
                    <div>
                        <h2 className="font-bold text-2xl">Step 3</h2>
                        <p>Get 10% off dine in or pick up</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DiscountSteps