const MenuItem = (name, cost, description) => {
    const getName = () => name
    const getCost = () => cost
    const getDesc = () => description

    return {
        getName,
        getCost,
        getDesc
    }
}

export default MenuItem