import React from "react";
import foodImage1 from "../assets/Food Images/Tacos Al Pastor.jpg"
import foodImage2 from "../assets/Food Images/2880x2304Selects/TaqueriaSaborMixteco_CamaronesAlMojoDeAjo_2880x2304.jpg" 
import foodImage3 from "../assets/Food Images/2880x2304Selects/TaqueriaSaborMixteco_CarneAsadaSopes_2880x2304.jpg" 
import foodImage4 from "../assets/Food Images/2880x2304Selects/TaqueriaSaborMixteco_Chileajo_2880x2304.jpg" 
import foodImage5 from "../assets/Food Images/2880x2304Selects/TaqueriaSaborMixteco_EnchiladasVerde_2880x2304.jpg" 
import foodImage6 from "../assets/Food Images/2880x2304Selects/TaqueriaSaborMixteco_Guac_2880x2304.jpg" 
import foodImage7 from "../assets/Food Images/2880x2304Selects/TaqueriaSaborMixteco_PassionFruit_2880x2304.jpg" 
import foodImage8 from "../assets/Food Images/2880x2304Selects/TaqueriaSaborMixteco_QuesoFondido_2880x2304.jpg" 
import foodImage9 from "../assets/Food Images/2880x2304Selects/TaqueriaSaborMixteco_Tacos_2880x2304.jpg" 
import foodImage10 from "../assets/Food Images/2880x2304Selects/TaqueriaSaborMixteco_Tamarind _2880x2304.jpg" 

import foodImage12 from "../assets/Food Images/compressed-images/TaquieraSaborMixteco_ChickenMoles.jpg"
import foodImage13 from "../assets/Food Images/compressed-images/TaquieraSaborMixteco_Chilajo.jpg"
import foodImage15 from "../assets/Food Images/compressed-images/TaquieraSaborMixteco_ChilaquilleseresWithScranbledEgges.jpg"
import foodImage16 from "../assets/Food Images/compressed-images/TaquieraSaborMixteco_Hero.jpg"
import foodImage18 from "../assets/Food Images/compressed-images/TaquieraSaborMixteco_SauderoAlambres.jpg"
import foodImage19 from "../assets/Food Images/compressed-images/TaquieraSaborMixteco_TacosAlPastor.jpg"
import foodImage20 from "../assets/Food Images/compressed-images/TaquieraSaborMixteco_Tlayudas.jpg"
import foodImage21 from "../assets/Food Images/compressed-images/TaquieraSaborMixteco_TorteCarneAsada.jpg"

function ImageGallery() {
    const images = [
        {image: foodImage1, alt: "Delicious tacos al pastor with salsa and other toppings"},
        {image: foodImage2, alt: "Camarones (Shrimp) Al Mojo De Ajo with black beans and rice"},
        {image: foodImage3, alt: "The best Carne asada with cream and chorizo sopes"},
        {image: foodImage4, alt: "Freshly cooked chileajo with rice and beans"},
        {image: foodImage5, alt: "Mexican style enchiladas verdes with mexican rice and brans"},
        {image: foodImage6, alt: "The best freshly made gucamole in the DMV area"},
        {image: foodImage7, alt: "A refreshing beverage of passion fruit"},
        {image: foodImage8, alt: "Queso (cheese) fundido with chorizo and tortillas"},
        {image: foodImage9, alt: "An asortment of pastor, and suadero tacos  with lime and radish"},
        {image: foodImage10, alt: "A refreshing beverage of tamarind"},
        {image: foodImage12, alt: "The best traditional chicken mole in Maryland"},
        {image: foodImage13, alt: "Delicious red chileajo topped with onions and served with rice and beans"},
        {image: foodImage15, alt: "Traditional mexican breakfast of chilaquiles and scrambled eggs with a side of beans and rice."},
        {image: foodImage16, alt: "An assortment of Taqueria Sabor Mixteco's best dishes served in the DMV area"},
        {image: foodImage18, alt: "An alambre of suadero topped with greens and melted cheese"},
        {image: foodImage19, alt: "A lunch special consisting of three delicios tacos al pastor with lime"},
        {image: foodImage20, alt: "Tlayuda topped with refried beans, cheese, avocado, tomato and carne enchilada"},
        {image: foodImage21, alt: "A mexican torta filled with carne asada, avocado, cheese, letture and onions"},
        
      ];

    return (
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
      {images.map((image, index) => (
        <img
          key={index}
          src={image.image}
          alt={image.alt}
          loading="lazy"
          className="w-full h-auto object-cover aspect-square rounded-lg"
        />
      ))}
    </div>

    )
} 

export default ImageGallery