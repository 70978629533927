import React, { useEffect, useState } from "react";
import menus from "./MenuList";
import MenuItemCard from "./MenuItemCard";
import menuPDF from "../assets/Menu.pdf"
import lunchPDF from "../assets/LunchMenu.pdf"
import downloadSVG from "../assets/Download.svg"

let {menu, lunchMenu} = menus

function Menu() {
    const [currMenu, setMenu] = useState(menu)

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'instant' });
    }, [])

    const sections = () => {
        return Object.entries(currMenu).map(([title, section]) => 
            <div className="py-4 px-3" key={title}>
                <h2 className="text-4xl text-bold" id={title}>{title}</h2>
                <p>{section[0]}</p>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 py-2">
                    {section[1].map((item) => <MenuItemCard key={item.getName()} item={item} />
                    )}
                </div>
            </div>
        )
    }

    const scrollToSection = (e) => {
        const title = e.target.textContent
        const element = document.getElementById(title)
        const navBar = document.getElementById("NavigationBar")
        const tuesdayBar = document.getElementById("TuesdayBar")
        const discountBar = document.getElementById("DiscountBar")
        if(element) {
            const scrollPosition = element.getBoundingClientRect().top + window.pageYOffset - navBar.getBoundingClientRect().height - discountBar.getBoundingClientRect().height - tuesdayBar.getBoundingClientRect().height  
            window.scrollTo({ top: scrollPosition, behavior: 'smooth'})
        }
    }

    const sectionBars = () => {
        return <div className="border border-black mt-6 mx-3">
            <ul className="flex justify-between flex-wrap overflow-auto items-center">
                {Object.keys(currMenu).map((title) => 
                    <li onClick={scrollToSection} key={title} className="sm:text-sm md:text-md lg:text-lg p-1 text-center">
                        {title !== "Signature Dishes" ? title : "Signatures"}
                    </li>
                )}
            </ul>
        </div>
    }

    const switchMenu = () => {
        setMenu(currMenu === menu ? lunchMenu : menu)
    }

    return(
        <div className="lg:px-32 xl:px-64">
            <div className="flex flex-col justify-center sm:p-3">
                <div className="flex items-end justify-center">
                    <h1 className="text-5xl font-bold text-center">
                        {currMenu === menu ? "Menu" : "Lunch Menu"}
                    </h1>
                    <a alt="Download current menu pdf" href={currMenu === menu ? menuPDF : lunchPDF} target="_blank" rel="noreferrer">
                            <button className="w-8 grow">
                                <img alt="Dowload file symbol" src={downloadSVG} />
                            </button>   
                    </a>
                </div>
                <h2 className="text-center">Monday - Friday, Until 3:00 PM</h2>
                <div className="flex">          
                    <button onClick={switchMenu} className="w-26 underline text-white bg-orange-500 ml-3 p-2 rounded-full">
                            {currMenu === menu ? "Lunch Menu" : "Menu"}
                    </button>    
                </div>   
                <div>{sectionBars()}</div>
                {sections()}
            </div>
        </div>
    )
}

export default Menu