import React, { useEffect } from "react";
import mainFoodImage from "../assets/Food Images/Tacos Al Pastor.jpg"
import isauroImage from "../assets/About Images/Isauro.jpg"

function About() {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'instant' });
    }, [])
    
    return(
        <div className="lg:px-32 xl:px-80">
            <div className="flex relative">
                <div className="absolute h-full w-full" >
                    <img className="h-full w-full inset-0 max-w-full" src={mainFoodImage} alt="Garnished tacos al pastor with salsa, onion and rajas" />
                </div>
                <div className="flex w-full justify-center relative py-48 lg:py-56 text-white bg-black bg-opacity-30"> 
                    <h1 className="px-2 text-5xl md:text-6xl lg:text-7xl text-center text-wrap">Taqueria Sabor Mixteco</h1>
                </div>
            </div>
            <div className="flex justify-center">
                <p className="px-12 py-12 text-center">
                    Welcome to 'Taqueria Sabor Mixteco' an extraordinary culinary restaurant 
                    that redefines the dining experience. Taqueria Sabor Mixteco is a place 
                    that brings together a harmonious fusion of flavors originating from many
                    regions and states from all over Mexico. As we prioritize your satisfaction
                    through our exceptional service, we invite you to experience our food 
                    as well as our warm and welcoming ambiance
                </p>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-20 px-12 pb-12">
                <div className="flex flex-col items-center"> 
                    <img className="" src={isauroImage} loading="lazy" alt="Isauro Solano Flores founder nad chef of Taqueria Sabor Mixteco"/>
                    <h3 className="text-xl pt-4">ISAURO SOLANO FLORES</h3>
                    <p>CHEF/FOUNDER</p>
                </div>
                {/* <div className="flex flex-col items-center">
                    <img className="aspect-square" src={placeholder} loading="lazy" alt="Isauro Solano Flores founder nad chef of Taqueria Sabor Mixteco"/>
                    <h3 className="text-xl pt-4">ISAURO SOLANO FLORES</h3>
                    <p>CHEF/FOUNDER</p>
                </div>
                <div className="flex flex-col items-center">
                    <img className="aspect-square" src={placeholder} loading="lazy" alt="Isauro Solano Flores founder nad chef of Taqueria Sabor Mixteco"/>
                    <h3 className="text-xl pt-4">ISAURO SOLANO FLORES</h3>
                    <p>CHEF/FOUNDER</p>
                </div> */}
            </div>
        </div>
    )
}

export default About