import React, { useEffect } from "react"
import mainFoodImage from "../assets/Food Images/2880x2304Selects/TaqueriaSaborMixteco_Hero_2880x2304.jpg"
import cateringFoodImage from "../assets/Food Images/Pozole.jpg"
import ImageGallery from "./ImageGallery"

function Home() {

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'instant' });
    }, [])

    const mapStyle = {
        border: '0',
    }

    return(
        <div className="lg:px-32 xl:px-80">
            <div className="flex relative">
                <div className="absolute h-full w-full" >
                    <img loading="lazy" className="h-full w-full inset-0 max-w-full" src={mainFoodImage} alt="A line up of fresh tortillas, delicious queso fundido, onions for garnish, guacamole, and nopales" />
                </div>
                <div className="flex relative h-full flex-1 flex-col justify-end gap-8 p-8 pt-64 text-white textShadow"> 
                    <h1 className="text-7xl font-semibold">Taqueria Sabor Mixteco</h1>
                    <p>Best Tacos in Silverspring</p>
                </div>
            </div>
            <div className="p-4">
                <h2 className="text-5xl font-bold text-center mb-10 mt-14">
                    Gallery
                </h2>
                <ImageGallery />
            </div>
            <div className="bg-transparent flex max-h-screen relative md:items-center">
                <div className="absolute h-full w-full">
                    <img loading="lazy" className="h-full w-full inset-0 max-w-full" src={cateringFoodImage} alt="Catering example with hand flipping tetelitas on grill" />
                </div>
                <div className="flex w-full px-2 pb-10 pt-[200px] md:p-20">
                    <div className="flex w-full items-center rounded-xl bg-black bg-opacity-70 text-white p-8 shadow-lg backdrop-blur-2xl md:min-h-[640px] md:max-w-[632px] md:p-20">
                        <div className="flex w-full flex-col gap-6 justify-around">
                            <h2 className="text-5xl text-center">
                                Catering & Events
                            </h2>
                            <p>
                            Whether you're planning a corporate luncheon, a milestone celebration, 
                            or an intimate gathering with friends, Sabor Mixteco is dedicated to delivering 
                            exceptional service and exquisite cuisine that will elevate your event to new 
                            heights.
                            </p>
                            <a alt="Go to Plan Catering Form" href="https://forms.gle/oDLiwHw9WgnbtwS4A"  target="_blank" rel="noreferrer">
                                <button className="self-center bg-orange-500 p-4 rounded-3xl font-bold w-40 hover:">
                                    Schedule Now!
                                </button>
                            </a>
                            
                        </div>
                    </div>
                </div>
            </div>
            <div className="orangeBG py-20 px-10">
                <div className="flex items-center justify-center flex-col sm:flex-row">
                    <div className="bg-sky-700 text-white h-[550px] p-6 flex flex-col">
                        <div>
                            <h3 className="text-3xl font-semibold py-5 border-b border-b-white">
                                Location & Contact
                            </h3>
                        </div>
                        <div className="flex flex-col text-lg justify-around flex-1">
                            <div>
                                <h4 className="text-xl font-semibold py-2">Address</h4>
                                <p>2462 Ennalls Ave, Silver Spring, MD 20902</p>
                            </div>
                            <div>
                                <h4 className="text-xl font-semibold py-2">Contacts</h4>
                                <a href="tel:+12405003833" className="underline">+1 (240) 500-3833</a>
                            </div>
                            <div>
                                <h4 className="text-xl font-semibold py-2">Opening Hours</h4>
                                <p>10:30 AM - 9:00 PM</p> 
                            </div>
                        </div>
                    </div>
                    <iframe className="h-[28rem] w-[90vw]" style={mapStyle} title="Taqueria Sabor Mixteco Location" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3098.9098001034663!2d-77.0549624240563!3d39.04017597169397!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b7cf01e1a173d5%3A0xe2c645cbfe322b33!2sTaqueria%20Sabor%20Mixteco!5e0!3m2!1sen!2sus!4v1710118581977!5m2!1sen!2sus" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </div>
    )
}

export default Home