import React from "react"
import { Link } from "react-router-dom"
import  logo from '../assets/Logo.svg'

function NavBar(props) {

    const {css} = props

    return(
        <nav id="NavigationBar" className={css}>
            <div className="flex justify-start items-center">
                <img src={logo} alt={"Sabor Mixteco Logo"} className="h-20 rounded-full" />
            </div>
            <ul className="flex-1 list-none flex max-w-80 justify-between items-center m-2 *:p-2 ">
                <li className="">
                    <Link to={''}>Home</Link>
                </li>
                <li>
                    <Link to={'menu'}>Menu</Link>
                </li>
                <li>
                    <Link to={'about'}>About</Link>
                </li>
                <li>
                    <Link to={'catering'}>Catering</Link>
                </li>
            </ul>
        </nav>
    )
}

export default NavBar